import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { useState, useEffect } from "react";
import axios from "axios";

import { useNavigate } from "react-router-dom";

export default function Dasbor() {
  const [modalConfirmDelete, setModalConfirmDelete] = useState(false);
  const [modalConfirmDeletePerson, setModalConfirmDeletePerson] = useState(false);
  const [modalDetailKeluarga, setModalDetailKeluarga] = useState(false);
  const [detailData, setDetailData] = useState([]);

  const navigate = useNavigate();

  const fetchDetailData = () => {
    let token = localStorage.getItem("token");
    let config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    axios
      .get(
        "https://bigdata.pelalawankab.go.id/api/penduduk/keluarga/1105030804100008",
        config
      )
      .then((response) => {
        const { data } = response;
        if (response.status === 200) {
          setDetailData(data.data);
          console.log(data.data);
        } else {
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchDetailData();
  }, []);

  return (
    <div className="w-full bg-slate-100">
    <Navbar />
      <div className="max-w-7xl mx-auto my-10 sm:px-6 lg:px-8">
        <div className="relative">
          <div className="absolute right-0">
            <button className="inline-flex items-center bg-slate-900 px-6 py-3 rounded-md hover:bg-slate-800">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="text-slate-100 h-4 w-4 mr-1"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
                />
              </svg>
              <p className="text-slate-100 text-xs font-semibold">Cetak</p>
            </button>
          </div>
        </div>
        <div>
          <h1 className="text-xl font-semibold mb-5">Detail Keluarga</h1>
        </div>

        <div className="mb-2">
          <label class="text-sm block text-gray-400">
            Nomor Kartu Keluarga
          </label>
          <div className="inline-flex items-center">
            <p className="text-2xl font-bold">{ detailData.kk }</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="ml-1 h-5 w-5 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
              />
            </svg>
          </div>
        </div>
        <div>
          <label class="text-sm block text-gray-400">Alamat</label>
          <p className="text-">
            { detailData.alamat_domisili }
          </p>
        </div>

        <hr class="my-5"></hr>
        <div class="w-full">
          <div class="flex flex-wrap">
            <div class="w-full w-1/3 pr-0 pr-2 mb-6">
              <div className="mb-2">
                <label class="text-sm block text-gray-400">
                  Kepala keluarga
                </label>
                <p className="text-md">{ detailData.nama_kepala_keluarga}</p>
              </div>
              <div className="mb-2">
                <label class="text-sm block text-gray-400">Kecamatan</label>
                <p className="text-md">{  }</p>
              </div>

              <div className="mb-2">
                <label class="text-sm block text-gray-400">Desa</label>
                <p className="text-md">{  }</p>
              </div>
              <div className="mb-2">
                <label class="text-sm block text-gray-400">Status Rumah</label>
                <p className="text-md">{ detailData.status_rumah }</p>
              </div>
              <div className="mb-2">
                <label class="text-sm block text-gray-400">
                  Penerangan Rumah
                </label>
                <p className="text-md">{ detailData.penerangan_rumah }</p>
              </div>
              <div className="mb-2">
                <label class="text-sm block text-gray-400">
                  Sumber air bersih
                </label>
                <p className="text-md">{ detailData.sumber_air_bersih }</p>
              </div>
              <div className="mb-2">
                <label class="text-sm block text-gray-400">
                  Jamban rumah tangga
                </label>
                <p className="text-md">{ detailData.jamban_rumah_tangga }</p>
              </div>
              <div className="mb-2">
                <label class="text-sm block text-gray-400">
                  Luas tanah tinggal
                </label>
                <p className="text-md">500 M²</p>
              </div>
              <div className="mb-2">
                <label class="text-sm block text-gray-400">
                  Luas rumah tinggal
                </label>
                <p className="text-md">400 M²</p>
              </div>
              <div>
                <label class="text-sm block text-gray-400">Aset</label>
                <p className="text-md">Kebun Sawit 300 ha</p>
                {/* <div className="flex flex-wrap">
            <span class="m-1 flex flex-wrap justify-between items-center text-xs bg-gray-300 rounded px-4 py-1 font-bold leading-loose">
              Kebun Sawit
            </span>
            <span class="m-1 flex flex-wrap justify-between items-center text-xs bg-gray-300 rounded px-4 py-1 font-bold leading-loose">
              SPBU
            </span>
          </div> */}
              </div>
            </div>

            <div class="w-full w-2/3">
              <div className="pb-3">
                <h2 className="font-medium text-gray-400 text-sm">
                  Anggota Keluarga :
                </h2>
              </div>
              <div className="flex flex-wrap">
                <div class="w-full w-1/2 pl-0">
                  <div class="px-5 py-4 max-w-md bg-[#fcfdff] rounded-lg border border-slate-200 mb-3 mr-3">
                    <p class="text-gray-800 text-md font-bold">
                      Hasan Suryaman
                    </p>
                    <p class="font-normal text-gray-500 text-xs">
                      1405020507930004
                    </p>
                    <p class="mb-2 font-bold text-gray-500 text-xs">Suami</p>
                    <div className="inline-flex items-center">
                      <a
                        className="cursor-pointer"
                        onClick={() => setModalDetailKeluarga(true)}
                      >
                        <div className="text-cyan-700 inline-flex items-center mr-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                            />
                          </svg>
                          <p className="text-xs font-semibold ml-1">
                            Lihat Detail
                          </p>
                        </div>
                      </a>
                      <a
                        className="cursor-pointer"
                        onClick={() => setModalConfirmDeletePerson(true)}
                      >
                        <div className="ml-2 text-red-700 inline-flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                          <p className="text-xs font-semibold ml-1">
                            Hapus Data
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="w-1/2">
                  <div class="px-5 py-4 max-w-md bg-[#fcfdff] rounded-lg border border-slate-200 mb-3">
                    <p class="text-gray-800 text-md font-bold">
                      Hasan Suryaman
                    </p>
                    <p class="font-normal text-gray-500 text-xs">
                      1405020507930004
                    </p>
                    <p class="mb-2 font-bold text-gray-500 text-xs">Istri</p>
                    <div className="inline-flex items-center">
                      <a href="">
                        <div className="text-cyan-700 inline-flex items-center mr-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                            />
                          </svg>
                          <p className="text-xs font-semibold ml-1">
                            Lihat Detail
                          </p>
                        </div>
                      </a>
                      <a href="">
                        <div className="ml-2 text-red-700 inline-flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                          <p className="text-xs font-semibold ml-1">
                            Hapus Data
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="w-1/2">
                  <div class="px-5 py-4 max-w-md bg-[#fcfdff] rounded-lg border border-slate-200 mb-3 mr-3">
                    <p class="text-gray-800 text-md font-bold">
                      Hasan Suryaman
                    </p>
                    <p class="font-normal text-gray-500 text-xs">
                      1405020507930004
                    </p>
                    <p class="mb-2 font-bold text-gray-500 text-xs">Anak</p>
                    <div className="inline-flex items-center">
                      <a href="">
                        <div className="text-cyan-700 inline-flex items-center mr-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                            />
                          </svg>
                          <p className="text-xs font-semibold ml-1">
                            Lihat Detail
                          </p>
                        </div>
                      </a>
                      <a href="">
                        <div className="ml-2 text-red-700 inline-flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                          <p className="text-xs font-semibold ml-1">
                            Hapus Data
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="w-1/2">
                  <div class="px-5 py-4 max-w-md bg-[#fcfdff] rounded-lg border border-slate-200 mb-3">
                    <p class="text-gray-800 text-md font-bold">
                      Hasan Suryaman
                    </p>
                    <p class="font-normal text-gray-500 text-xs">
                      1405020507930004
                    </p>
                    <p class="mb-2 font-bold text-gray-500 text-xs">Anak</p>
                    <div className="inline-flex items-center">
                      <a href="">
                        <div className="text-cyan-700 inline-flex items-center mr-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                            />
                          </svg>
                          <p className="text-xs font-semibold ml-1">
                            Lihat Detail
                          </p>
                        </div>
                      </a>
                      <a href="">
                        <div className="ml-2 text-red-700 inline-flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                          <p className="text-xs font-semibold ml-1">
                            Hapus Data
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-auto bg-slate-200 py-10 px-10 rounded-md mt-6">
          <p className="text-xs font-medium mb-1">Data diinput oleh:</p>
          <div className="inline-flex items-center">
            {/* <img
              class="w-8 h-8 rounded-full"
              src="https://flowbite.com/docs/images/people/profile-picture-5.jpg"
              alt="Rounded avatar"
            ></img> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-12 w-12 text-slate-400"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                clipRule="evenodd"
              />
            </svg>
            <div className="ml-2">
              <p className="text-sm font-semibold">Hasan Suryaman</p>
              <p className="text-xs">16 Agustus 2022</p>
              <p className="text-xs">Waktu 14:20:55</p>
            </div>
          </div>
        </div>

        <div className="space-x-2 mt-14">
          <button className="inline-flex items-center px-5 py-3 text-sm font-medium text-white bg-cyan-700 hover:bg-cyan-800 rounded-md font-semibold transition ease-in-out duration-300 sm:px-8 sm:py-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="mr-2 h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
              />
            </svg>
            Edit
          </button>

          <button
            onClick={() => navigate(-1)}
            className="inline-flex items-center px-5 py-3 text-sm font-medium text-white bg-blue-700 hover:bg-blue-800 rounded-md font-semibold transition ease-in-out duration-300 sm:px-8 sm:py-3 "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="mr-2 h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11 17l-5-5m0 0l5-5m-5 5h12"
              />
            </svg>
            Kembali
          </button>

          <button
            className="inline-flex float-right items-center px-5 py-3 text-sm font-medium text-white bg-red-500 hover:bg-red-600 rounded-md font-semibold transition ease-in-out duration-300 sm:px-8 sm:py-3 "
            onClick={() => setModalConfirmDelete(true)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="mr-2 h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              />
            </svg>
            Hapus
          </button>
        </div>
      </div>
<Footer />
      {modalConfirmDelete ? (
        <>
          <div
            class="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div class="fixed inset-0 bg-slate-800 bg-opacity-75 backdrop-blur-sm transition-opacity"></div>

            <div class="fixed z-10 inset-0 overflow-y-auto">
              <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                <div class="relative bg-slate-100 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                  <div class="bg-slate-100 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div class="sm:flex sm:items-start">
                      <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-15 sm:w-15">
                        <svg
                          class="h-8 w-8 text-red-600"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                          />
                        </svg>
                      </div>
                      <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <h3
                          class="text-lg font-bold text-gray-900"
                          id="modal-title"
                        >
                          Hapus data keluarga?
                        </h3>
                        <div class="mt-2">
                          <p class="text-sm text-gray-500">
                            Menghapus data keluarga juga akan menghapus seluruh
                            anggota keluarga, pastikan data yang akan anda hapus
                            terlebih dahulu. Data yang sudah dihapus tidak dapat
                            dikembalikan
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="bg-slate-100 px-4 pt-3 pb-6 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      onClick={() => setModalConfirmDelete(false)}
                      class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700  focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    >
                      Hapus Data Keluarga
                    </button>
                    <button
                      type="button"
                      onClick={() => setModalConfirmDelete(false)}
                      class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    >
                      Batal
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {modalConfirmDeletePerson ? (
        <>
          <div
            class="relative z-10 over"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div class="fixed inset-0 bg-slate-800 bg-opacity-75 backdrop-blur-sm transition-opacity"></div>

            <div class="fixed z-10 inset-0 overflow-y-auto">
              <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                <div class="relative bg-slate-100 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                  <div class="bg-slate-100 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div class="sm:flex sm:items-start">
                      <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-200 sm:mx-0 sm:h-15 sm:w-15">
                        <svg
                          class="h-8 w-8 text-red-600"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                          />
                        </svg>
                      </div>
                      <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <h3
                          class="text-lg font-bold text-gray-900"
                          id="modal-title"
                        >
                          Hapus Anggota Keluarga?
                        </h3>
                        <div class="mt-2">
                          <p class="text-sm text-gray-500">
                            Pastikan data anggota keluarga yang ingin anda
                            hapus, data yang sudah dihapus tidak dapat
                            dikembalikan.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="bg-slate-100 pt-3 pb-6 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      onClick={() => setModalConfirmDeletePerson(false)}
                      class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                    >
                      Hapus Anggota Keluarga
                    </button>
                    <button
                      type="button"
                      onClick={() => setModalConfirmDeletePerson(false)}
                      class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    >
                      Batal
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {modalDetailKeluarga ? (
        <>
          <div className="fixed inset-0 z-10 overflow-y-auto ">
            {/* <div
              className="fixed inset-0 w-full h-full bg-black opacity-70"
              onClick={() => setModalDetailKeluarga(false)}
            ></div> */}
            <div
              class="fixed inset-0 bg-slate-800 bg-opacity-75 backdrop-blur-sm transition-opacity "
              onClick={() => setModalDetailKeluarga(false)}
            ></div>
            <div className="flex items-center min-h-screen px-4 py-8">
              <div className="relative w-full max-w-4xl p-4 mx-auto bg-slate-100 rounded-md shadow-lg">
                <div className="m-6">
                  <div className="mt-2 text-center sm:ml-4 sm:text-left">
                    <h4 className="text-lg font-bold text-gray-800">
                      Detail Anggota Keluarga
                    </h4>
                    <div className="mt-4">
                      <div className="mb-2">
                        <label class="text-xs block text-gray-400">
                          Nomor Induk Kepandudukan (NIK)
                        </label>
                        <p className="font-bold text-2xl">1405020507930004</p>
                      </div>
                      <div className="mb-2">
                        <label class="text-xs block text-gray-400">
                          Nama Lengkap
                        </label>
                        <p className="font-bold text-xl">Hasan Suryaman</p>
                      </div>
                      <hr class="my-5"></hr>
                      <div className="mb-2">
                        <label class="text-xs block text-gray-400">Umur</label>
                        <p className="">29 Tahun</p>
                      </div>
                      <div className="mb-2">
                        <label class="text-xs block text-gray-400">
                          Jenis kelamin
                        </label>
                        <p className="">Laki-laki</p>
                      </div>
                      <div className="mb-2">
                        <label class="text-xs block text-gray-400">
                          Kecamatan
                        </label>
                        <p className="">Pangkalan Kerinci Kota</p>
                      </div>
                      <div className="mb-2">
                        <label class="text-xs block text-gray-400">
                          Desa/ Kelurahan
                        </label>
                        <p className="">Pangkalan Kerinci Kota</p>
                      </div>
                      <div className="mb-2">
                        <label class="text-xs block text-gray-400">
                          Penyakit yang di derita
                        </label>
                        <p className="">-</p>
                      </div>
                      <div className="mb-2">
                        <label class="text-xs block text-gray-400">
                          Tanggungan asuransi bulanan
                        </label>
                        <p className="">1.000.000/ Bulan</p>
                      </div>
                      <div className="flex">
                        <div class="w-full w-1/2 pr-0 pr-2 mb-6">
                          <p className="font-bold text-lg mt-6 mb-2">
                            Pekerjaan
                          </p>
                          <div className="mb-2">
                            <label class="text-xs block text-gray-400">
                              Pekerjaan
                            </label>
                            <p className="">Kuli</p>
                          </div>
                          <div className="mb-2">
                            <label class="text-xs block text-gray-400">
                              Penghasilan Bulanan
                            </label>
                            <p className="">Rp. 3.000.000</p>
                          </div>
                          <div className="mb-2">
                            <label class="text-xs block text-gray-400">
                              Penghasilan lainnya
                            </label>
                            <p className="">-</p>
                          </div>
                        </div>
                        <div class="w-full w-1/2 pr-0 pr-2 mb-6">
                          <p className="font-bold text-lg mt-6 mb-2">
                            Pendidikan
                          </p>
                          <div className="mb-2">
                            <label class="text-xs block text-gray-400">
                              Pendidikan terakhir
                            </label>
                            <p className="">S1</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="-mx-10"></hr>
                  <div className="mt-6 -mb-4 flex justify-center">
                    <span className="inline-flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4 text-slate-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      <p className="items-center justify-center text-xs text-slate-500 ml-1">
                        Untuk melakukan edit dan perubahan data dapat dilakukan
                        melalui edit data keluarga
                      </p>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}
