import { ArrowDownTrayIcon, LightningBoltIcon } from '@heroicons/react/24/solid'

export default function Header2(){
return(
  <div class="bg-cyan-800">
<div class="container max-w-7xl mx-auto sm:px-6 lg:px-8">
  <div class="w-full py-10">
    <p class="text-white italic text-sm mb-2">Selamat datang,</p>
    <span
      class="text-white text-2xl font-medium py-4 xl:py-8">
      Hasan Suryaman
    </span>
    <p class="text-sm text-white mr-2 my-4">Anda terdaftar sebagai petugas JagaWarga untuk wilayah <span class="font-bold">Desa Pangkalan Panduk</span> pada <span class="font-bold">Kecamatan Kerumutan</span>
    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
    </p>
    {/* <button class="inline-flex py-4 px-8 bg-gray-800 mt-10 text-white text-sm rounded-md mr-3">Mulai Sekarang
    <LightningBoltIcon className="h-5 w-5 ml-2"/></button> */}
    <button class="inline-flex py-4 px-8 bg-gray-800 text-white text-sm rounded-md my-4">
      Panduan Penggunaan
      <ArrowDownTrayIcon className="h-5 w-5 ml-2"/>
    </button>
  </div>
  <div class="w-full lg:1/2 mt-4 lg:mt-0 space-y-4 px-4 sm:px-16 md:px-32 lg:px-0 xl:mx-24 lg:mx-0 items-center">
  </div>
</div>
</div>
)
}