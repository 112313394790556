import { Link } from "react-router-dom"
import { ArrowLeftIcon, ExclamationCircleIcon } from '@heroicons/react/24/solid'

export default function Oops() {
    return (
    <div class="flex flex-col lg:flex-row h-screen bg-slate-100">
<div class="w-full h-screen flex items-center justify-center">
<div class="container text-center py-12 mx-auto">
<h1 class="text-4xl font-bold mb-20 italic text-gray-700">JagaWarga</h1>
{/* <ExclamationCircleIcon className="h-20 w-20 inline mb-6"/> */}
        <h1 class="text-6xl font-bold mb-2 italic">404</h1>
  <h2 class="text-3xl font-semibold mb-4 text-gray-700">Halaman tidak ditemukan</h2>
  <p class="text-gray-700 mb-20">Maaf, halaman yang anda cari tidak ditemukan. pastikan url yang anda tuju sudah benar!</p>
  <button>
    <Link 
        to="/"
        className="bg-cyan-700 hover:bg-cyan-800 text-white font-bold py-3 px-5 rounded hover:py-4 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
    >
        <ArrowLeftIcon className="h-4 w-4 inline mr-2"/>
        Kembali ke halaman utama
    </Link>
  </button>
</div>
</div>
</div>
    )
}