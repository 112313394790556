import React, { useState, useRef } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';

export default function Example() {
  const errRef = useRef();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const LoginProcess = async (e) => {
    e.preventDefault();
    // setLoading(true);
    if (username != "" && password != "") {
      try {
        axios
          .post("https://bigdata.pelalawankab.go.id/api/auth/user-login", {
            username: username,
            password: password,
          })
          .then((res) => {
            localStorage.setItem("token", res.data.token);
            // console.log(res.data);
            if (res.data.token) {
              // setTimeout(() => {
                // setLoading(false);
                navigate("/dasbor");
              // }, 1000);
              
            }
          })
          .catch((err) =>
            err.response.status === 401
              ? toast("Username atau Password salah",{
                style: {
                  borderRadius: '10px',
                  background: '#333',
                  color: '#fff',
                },
              })
              : toast("Terjadi kesalahan koneksi")
          );
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.error("Username atau Password tidak boleh kosong");
      // setErrMsg("Username atau Password tidak boleh kosong");
    }
  };

  return (
    <div className="flex flex-col lg:flex-row h-screen bg-slate-100">
      <Toaster
        position="bottom-right"
        // reverseOrder={false}
      />
      <div className="w-full lg:w-1/2 h-screen flex items-center justify-center">
        <div className="max-w-md mx-7 md:mx-0">
          <h1 className="text-2xl lg:text-4xl tracking-tight leading-none font-black text-cyan-700 animate-text font-jakarta italic">
            Login
          </h1>
          <p className="text-base text-gray-500 mt-2 font-jakarta lg:text-md">
            Masukkan <span className="italic font-semibold">username</span> dan{" "}
            <span className="italic font-semibold">password</span> anda untuk
            masuk kedalam dasbor
          </p>

          <form
            action=""
            onSubmit={LoginProcess}
            className="mx-auto mt-6 mb-0 space-y-4"
          >
            <div>
              <label htmlFor="username" className="sr-only">
                Username
              </label>

              <div className="relative">
                <input
                  type="text"
                  autoComplete="username"
                  className="w-full p-4 pr-12 text-sm rounded-lg shadow-sm focus:outline-cyan-700 focus:outline-offset-0 focus:text-cyan-700 focus:bg-gray-100 text-cyan-700 font-medium"
                  placeholder="Masukkan username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />

                <span className="absolute inset-y-0 inline-flex items-center right-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                    />
                  </svg>
                </span>
              </div>
            </div>

            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <div className="relative">
                <input
                  type="password"
                  autoComplete="current-password"
                  className="w-full p-4 pr-12 text-sm rounded-lg shadow-sm focus:outline-cyan-700 focus:outline-offset-0 focus:text-cyan-700 focus:bg-gray-100 text-cyan-700 font-medium"
                  placeholder="Masukkan password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />

                <span className="absolute inset-y-0 inline-flex items-center right-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                    />
                  </svg>
                </span>
              </div>
            </div>

            <div>
              <p
                ref={errRef}
                className={
                  errMsg
                    ? "errMsg text-red-500 font-medium text-sm"
                    : "offscreen"
                }
                aria-live="assertive"
              >
                {errMsg}
              </p>
            </div>
            <div className="flex items-center justify-between">
              <p className="text-sm text-gray-500">
                Belum punya akun?{" "}
                <Link className="underline" to="/signup">
                  Daftar
                </Link>
              </p>

              {loading ? (
                <button
                  type="button"
                  className="inline-flex items-center px-5 py-3 font-semibold leading-6 text-sm shadow rounded-md text-white bg-indigo-500 hover:bg-indigo-400 transition ease-in-out duration-150 cursor-not-allowed"
                  disabled=""
                >
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Dikirim...
                </button>
              ) : (
                <button
                  type="submit"
                  className="inline-block px-5 py-3 text-sm font-medium text-white bg-blue-700 hover:bg-blue-800 rounded-md font-semibold transition ease-in-out duration-300 sm:px-8 sm:py-3 "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4 inline mr-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
                    />
                  </svg>
                  Masuk
                </button>
              )}
            </div>
          </form>

          {/* <div class="md:flex md:items-center"> */}
          {/* <img class=" h-16 mt-20 mx-auto" src={require("../assets/google-play-badge.png")} /> */}
          {/* </div>  */}
        </div>
      </div>

      <div className="w-full lg:w-1/2 md:h-1/2 h-screen hidden lg:block">
        <div className="object-cover w-full h-screen from-transparent to-blue-200 bg-pelalawan bg-cover bg-center"></div>
        <div className="absolute top-0 right-0 bottom-0 left-1/2 lg:w-1/2 bg-gradient-to-l from-transparent to-cyan-900"></div>
      </div>
      <div className="py-1 bg-cyan-700 md:flex md:items-center md:justify-between"></div>
    </div>
  );
}
