import './App.css';

import {
  Navigate,
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import HomePage from "./pages/Home";
import SignupPage from './pages/Signup';
import LoginPage from './pages/Login';
import DasborPage from './pages/Dasbor';
import DataPage from './pages/Data';
import KeluarPage from './pages/Keluar';
import TambahPage from './pages/Tambah';
import DetailPage from './pages/Detail';
import TentangPage from './pages/Tentang';
import OopsPage from './pages/Oops';


function App() {
  return (
    
     <BrowserRouter>
        <Routes>
            <Route path="/" element={<HomePage/>} />
            <Route path="/login" element={<LoginPage/>} />
            <Route path="/signup" element={<SignupPage/>} />
            <Route path="/dasbor" element={<DasborPage/>} />
            <Route path="/data" element={<DataPage/>} />
            <Route path="/detail" element={<DetailPage/>} />
            <Route path="/keluar" element={<KeluarPage/>} />
            <Route path="/tambah" element={<TambahPage/>} />
            <Route path="/tentang" element={<TentangPage/>} />
            <Route path="/oops" element={<OopsPage/>} />
            <Route path="*" element={<Navigate to="oops" />} />

        </Routes>
      </BrowserRouter>

      
  );
  
}

export default App;