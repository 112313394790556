import { AtSymbolIcon, GlobeAltIcon, HeartIcon, LifebuoyIcon } from '@heroicons/react/24/solid'

export default function Footer(){
    return(
<footer class="text-gray-600 body-font">
  <div class="container max-w-7xl mx-auto px-5 py-8 mx-auto flex items-center sm:flex-row flex-col">
    <a class="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
      <span class="ml-3 text-lg italic font-bold">JagaWarga</span>
    </a>
    <p class="text-sm text-gray-500 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4">© 2022 
    <span class="inline-flex mx-1 justify-center">
    <HeartIcon className="h-4 w-4 text-red-500 fill-red-500"/>
</span>
      <a href="https://diskominfo.pelalawankab.go.id" class="text-gray-600 ml-1" rel="noopener noreferrer" target="_blank">Diskomifo Pelalawan</a>
    </p>
    <span class="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
      <a class="ml-3 text-gray-500">
      <LifebuoyIcon className="h-4 w-4"/>
      </a>
      <a class="ml-3 text-gray-500">
      <GlobeAltIcon className="h-4 w-4"/>
      </a>
      <a class="ml-3 text-gray-500">
      <AtSymbolIcon className="h-4 w-4"/>
      </a>
    </span>
  </div>
</footer>
    )
}