import { Link } from "react-router-dom";

export default function Example() {
  return (
    <div className="flex flex-col lg:flex-row h-screen bg-slate-100">
      <div className="w-full lg:w-1/2 h-screen flex items-center justify-center">
        <div className="w-4/6">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl tracking-tight leading-none font-black text-cyan-700 animate-text font-jakarta italic">
            JagaWarga
          </h1>
          <p className="text-base text-gray-500 mt-2 font-jakarta lg:text-md">
            lorem Members of an intergalactic alliance paving the way for peace
            and benevolence among all species. They are known for their
            enthusiasm for science, for their love of fun, and their dedication
            to education.
          </p>

          <Link
            to="/signup"
            className="inline-block bg-cyan-600 hover:bg-cyan-700 mt-5 px-4 py-2 rounded-md text-white font-semibold transition ease-in-out duration-300 sm:px-8 sm:py-3 font-jakarta"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 inline mr-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
              />
            </svg>
            Daftar
          </Link>
          <Link
            to="/login"
            className="inline-block bg-blue-600 hover:bg-blue-700 ml-2 px-4 py-2 rounded-md text-white font-semibold transition ease-in-out duration-300 sm:px-8 sm:py-3 font-jakarta"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 inline mr-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
              />
            </svg>
            Masuk
          </Link>
          {/* <div className="md:flex md:items-center"> */}
          {/* <img className=" h-16 mt-20 mx-auto" src={require("../assets/google-play-badge.png")} /> */}
          {/* </div>  */}
        </div>
      </div>
      <div className="w-full lg:w-1/2 md:h-1/2 h-screen hidden lg:block">
        <div className="object-cover w-full h-screen from-transparent to-blue-200 bg-pelalawan bg-cover bg-center"></div>
        <div className="absolute top-0 right-0 bottom-0 left-1/2 lg:w-1/2 bg-gradient-to-l from-transparent to-cyan-900"></div>
      </div>
      <div className="py-1 bg-cyan-700 md:flex md:items-center md:justify-between"></div>
    </div>
  );
}
