import { useState, useEffect } from "react";
import { CheckIcon, PlusIcon, XMarkIcon } from "@heroicons/react/24/solid";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";

export default function Dasbor() {
  const [KK, setKK] = useState("");
  const [kepalaKeluarga, setKepalaKeluarga] = useState("");
  const [jumlahAnak, setJumlahAnak] = useState("");
  const [alamatDomisili, setAlamatDomisili] = useState("");
  const [desa, setDesa] = useState("");
  const [kecamatan, setKecamatan] = useState("");
  const [statusRumah, setStatusRumah] = useState("");
  const [peneranganRumah, setPeneranganRumah] = useState("");
  const [sumberAirBersih, setSumberAirBersih] = useState("");
  const [jambanRumahTangga, setJambanRumahTangga] = useState("");
  const [luasTanahTinggal, setLuasTanahTinggal] = useState("");
  const [luasRumahTinggal, setLuasRumahTinggal] = useState("");
  const [jarakPuskesmas, setJarakPuskesmas] = useState("");
  const [aset, setAset] = useState("");

  const [pendidikan, setPendidikan] = useState("");

  const [optionPendidikan, setOptionPendidikan] = useState([]);
  const [optionKecamatan, setOptionKecamatan] = useState([]);
  const [optionDesa, setOptionDesa] = useState([]);

  const [msg, setMsg] = useState("");

  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);

  const token = localStorage.getItem("token");

  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  const TambahKK = async (e) => {
    e.preventDefault();
    // this.setLoading({
    //   loading: true
    // })
    // setTimeout(() => {
    //   this.setLoading({ loading: false });
    // }, 3000);

    const data = {
      kk: KK,
      nama_kepala_keluarga: kepalaKeluarga,
      jumlah_anak: jumlahAnak,
      alamat_domisili: alamatDomisili,
      desa_id: desa,
      kecamatan_id: kecamatan,
      status_rumah: statusRumah,
      penerangan_rumah: peneranganRumah,
      sumber_air_bersih: sumberAirBersih,
      jamban_rumah_tangga: jambanRumahTangga,
      luas_tanah_tinggal: luasTanahTinggal,
      luas_rumah_tinggal: luasRumahTinggal,
      jarak_puskesmas: jarakPuskesmas,
      aset: aset,
    };

    try {
      axios
        .post(
          "https://bigdata.pelalawankab.go.id/api/penduduk/keluarga",
          data,
          config
        )
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const fetchPendidikan = () => {
    axios
      .get(
        "https://bigdata.pelalawankab.go.id/api/general/jenjang-pendidikan",
        config
      )
      .then((res) => {
        setOptionPendidikan(res.data.data);
        console.log(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (showModal) {
      fetchPendidikan();
    }
  }, [showModal]);

  const fetchKecamatan = () => {
    axios
      .get("https://bigdata.pelalawankab.go.id/api/general/kecamatan")
      .then((response) => {
        const { data } = response;
        if (response.status === 200) {
          //check the api call is success by stats code 200,201 ...etc
          setOptionKecamatan(data.data);
        } else {
          //error handle section
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchKecamatan();
  }, []);

  const fetchDesa = () => {
    axios
      .get(
        `https://bigdata.pelalawankab.go.id/api/general/filter-kecamatan/${kecamatan}`
      )
      .then((response) => {
        const { data } = response;
        if (response.status === 200) {
          //check the api call is success by stats code 200,201 ...etc
          setOptionDesa(data.data.list_desa);
        } else {
          //error handle section
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (kecamatan !== "") {
      fetchDesa();
    }
  }, [kecamatan]);

  return (
    <div class="bg-slate-100">
      <Navbar />
      <div class="max-w-7xl mx-auto sm:px-6 lg:px-8 ">
        <div class="p-8 rounded-md border border-gray-300 my-10 bg-slate-100">
          <h1 class="font-bold text-2xl">Tambah Data Keluarga</h1>
          <div class="mt-6">
            <p class="text-gray-600 inline-flex items-center">
              <span class="inline-block mr-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
              Silahkan isi form dibawah ini untuk penambahan data keluarga baru.
            </p>
          </div>
          <form onSubmit={TambahKK}>
            <div class="mt-6 space-y-6">
              <div>
                <label
                  for="kk"
                  class="text-sm text-gray-700 block mb-1 font-medium"
                >
                  Nomor KK
                </label>
                <input
                  maxLength={16}
                  minLength={16}
                  type="text"
                  value={KK}
                  name="kk"
                  id="kk"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(e) => setKK(e.target.value)}
                  class="w-full p-4 pr-12 text-sm rounded-lg shadow-sm focus:outline-cyan-700 focus:outline-offset-0 focus:text-cyan-700 focus:bg-gray-100 text-cyan-700 font-medium"
                  autocomplete="off"
                  placeholder="Masukkan Nomor KK"
                />
              </div>
              <div>
                <label
                  for="kepala-keluarga"
                  class="text-sm text-gray-700 block mb-1 font-medium"
                >
                  Kepala Keluarga
                </label>
                <input
                  type="text"
                  value={kepalaKeluarga}
                  onChange={(e) => setKepalaKeluarga(e.target.value)}
                  name="kepala-keluarga"
                  id="kepala-keluarga"
                  class="w-full p-4 pr-12 text-sm rounded-lg shadow-sm focus:outline-cyan-700 focus:outline-offset-0 focus:text-cyan-700 focus:bg-gray-100 text-cyan-700 font-medium"
                  placeholder="Masukkan Nama Kepala Keluarga"
                />
              </div>
              <div>
                <label
                  for="jumlah-anak"
                  class="text-sm text-gray-700 block mb-1 font-medium"
                >
                  Jumlah Anak
                </label>
                <input
                  type="text"
                  value={jumlahAnak}
                  onChange={(e) => setJumlahAnak(e.target.value)}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  maxLength={2}
                  minLength={1}
                  class="w-full p-4 pr-12 text-sm rounded-lg shadow-sm focus:outline-cyan-700 focus:outline-offset-0 focus:text-cyan-700 focus:bg-gray-100 text-cyan-700 font-medium"
                  placeholder="Masukkan jumlah anak"
                />
              </div>
              <div class="mt-4">
                <label
                  for="alamat-domisili"
                  class="text-sm text-gray-700 block mb-1 font-medium"
                >
                  Alamat Domisili
                </label>
                <textarea
                  type="text"
                  value={alamatDomisili}
                  onChange={(e) => setAlamatDomisili(e.target.value)}
                  name="aset"
                  id="aset"
                  class="w-full p-4 pr-12 text-sm rounded-lg shadow-sm focus:outline-cyan-700 focus:outline-offset-0 focus:text-cyan-700 focus:bg-gray-100 text-cyan-700 font-medium"
                  placeholder="Masukkan Alamat"
                />
              </div>
              <div class="w-full">
                <div class="flex flex-wrap">
                  <div class="w-full md:w-1/2 pr-0 md:pr-2 mb-6">
                    <label
                      for="kecamatan"
                      class="text-sm text-gray-700 block mb-1 font-medium"
                    >
                      Kecamatan
                    </label>
                    <select
                      id="kecamatan"
                      disabled={false}
                      value={kecamatan}
                      onChange={(e) => setKecamatan(e.currentTarget.value)}
                      defaultValue=""
                      class="bg-white rounded-lg shadow-sm text-cyan-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full font-bold py-4 focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 font-bold focus:outline-cyan-700 focus:outline-offset-0 focus:text-cyan-700 focus:bg-gray-100"
                    >
                      <option value="" disabled>
                        Pilih Kecamatan
                      </option>
                      {optionKecamatan.map((item) => (
                        <option key={item._id} value={item.id}>
                          {item.nama}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div class="w-full md:w-1/2 pl-0 md:pl-2">
                    <label
                      for="desa"
                      class="text-sm text-gray-700 block mb-1 font-medium"
                    >
                      Desa
                    </label>
                    <select
                      id="desa"
                      disabled={false}
                      value={desa}
                      onChange={(e) => setDesa(e.currentTarget.value)}
                      defaultValue=""
                      class="bg-white rounded-lg shadow-sm text-cyan-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full font-bold py-4 focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 font-bold focus:outline-cyan-700 focus:outline-offset-0 focus:text-cyan-700 focus:bg-gray-100"
                    >
                      <option value="" disabled>
                        Pilih Desa
                      </option>
                      {optionDesa.map((item) => (
                        <option key={item._id} value={item.id}>
                          {item.nama}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div>
                <label
                  for="status-rumah"
                  class="text-sm text-gray-700 block mb-1 font-medium"
                >
                  Status Rumah
                </label>
                <select
                  required
                  id="status-rumah"
                  value={statusRumah}
                  onChange={(e) => setStatusRumah(e.currentTarget.value)}
                  class="bg-white rounded-lg shadow-sm text-cyan-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full font-bold py-4 focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 font-bold focus:outline-cyan-700 focus:outline-offset-0 focus:text-cyan-700 focus:bg-gray-100"
                >
                  <option value="" disabled>
                    Pilih Status Rumah
                  </option>
                  <option value="Sendiri">Sendiri</option>
                  <option value="Kontrak">Kontrak</option>
                  <option value="Menumpang">Menumpang</option>
                </select>
              </div>

              <div>
                <label
                  for="jarak_puskesmas"
                  class="text-sm text-gray-700 block mb-1 font-medium"
                >
                  Jarak dari Puskesmas
                </label>
                <input
                  type="text"
                  value={jarakPuskesmas}
                  onChange={(e) => setJarakPuskesmas(e.target.value)}
                  name="jarak_puskesmas"
                  id="jarak_puskesmas"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  class="w-full p-4 pr-12 text-sm rounded-lg shadow-sm focus:outline-cyan-700 focus:outline-offset-0 focus:text-cyan-700 focus:bg-gray-100 text-cyan-700 font-medium"
                  autocomplete="off"
                  placeholder="Masukkan Jarak Rumah ke Puskesmas (Meter)"
                />
              </div>
              <div>
                <label
                  for="penerangan-rumah"
                  class="text-sm text-gray-700 block mb-1 font-medium"
                >
                  Penerangan Rumah
                </label>
                <select
                  required
                  id="penerangan-rumah"
                  value={peneranganRumah}
                  onChange={(e) => setPeneranganRumah(e.currentTarget.value)}
                  class="bg-white rounded-lg shadow-sm text-cyan-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full font-bold py-4 focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 font-bold focus:outline-cyan-700 focus:outline-offset-0 focus:text-cyan-700 focus:bg-gray-100"
                >
                  <option value="" disabled>
                    Pilih Penerangan Rumah
                  </option>
                  <option value="PLN">PLN</option>
                  <option value="BUMD">BUMD</option>
                  <option value="Genset">Genset</option>
                  <option value="Lampu Minyak">Lampu Minyak</option>
                </select>
              </div>
              <div>
                <label
                  for="sumber-air"
                  class="text-sm text-gray-700 block mb-1 font-medium"
                >
                  Sumber Air Bersih
                </label>
                <input
                  type="text"
                  value={sumberAirBersih}
                  onChange={(e) => setSumberAirBersih(e.target.value)}
                  name="sumber-air"
                  id="sumber-air"
                  class="w-full p-4 pr-12 text-sm rounded-lg shadow-sm focus:outline-cyan-700 focus:outline-offset-0 focus:text-cyan-700 focus:bg-gray-100 text-cyan-700 font-medium"
                  placeholder="Masukkan Sumber Air Berish"
                />
              </div>
              <div>
                <label
                  for="jamban-rumah"
                  class="text-sm text-gray-700 block mb-1 font-medium"
                >
                  Jamban Rumah Tangga
                </label>
                <input
                  type="text"
                  value={jambanRumahTangga}
                  onChange={(e) => setJambanRumahTangga(e.target.value)}
                  name="jamban-rumah"
                  id="jamban-rumah"
                  class="w-full p-4 pr-12 text-sm rounded-lg shadow-sm focus:outline-cyan-700 focus:outline-offset-0 focus:text-cyan-700 focus:bg-gray-100 text-cyan-700 font-medium"
                  placeholder="Masukkan "
                />
              </div>
              <div>
                <label
                  for="luas-tanah"
                  class="text-sm text-gray-700 block mb-1 font-medium"
                >
                  Luas Tanah Tinggal
                </label>
                <input
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  value={luasTanahTinggal}
                  onChange={(e) => setLuasTanahTinggal(e.target.value)}
                  type="text"
                  name="luas-tanah"
                  id="luas-tanah"
                  class="w-full p-4 pr-12 text-sm rounded-lg shadow-sm focus:outline-cyan-700 focus:outline-offset-0 focus:text-cyan-700 focus:bg-gray-100 text-cyan-700 font-medium"
                  placeholder="Masukkan Luas Tanah (M²)"
                />
              </div>

              <div>
                <label
                  for="luas-rumah"
                  class="text-sm text-gray-700 block mb-1 font-medium"
                >
                  Luas Rumah Tinggal
                </label>
                <input
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  value={luasRumahTinggal}
                  onChange={(e) => setLuasRumahTinggal(e.target.value)}
                  type="text"
                  name="luas-tanah"
                  id="luas-tanah"
                  class="w-full p-4 pr-12 text-sm rounded-lg shadow-sm focus:outline-cyan-700 focus:outline-offset-0 focus:text-cyan-700 focus:bg-gray-100 text-cyan-700 font-medium"
                  placeholder="Masukkan Luas Rumah (M²)"
                />
              </div>
              <div>
                <label
                  for="aset"
                  class="text-sm text-gray-700 block mb-1 font-medium"
                >
                  Aset
                </label>
                <textarea
                  value={aset}
                  onChange={(e) => setAset(e.target.value)}
                  type="text"
                  name="aset"
                  id="aset"
                  class="w-full p-4 pr-12 text-sm rounded-lg shadow-sm focus:outline-cyan-700 focus:outline-offset-0 focus:text-cyan-700 focus:bg-gray-100 text-cyan-700 font-medium"
                  placeholder="Masukkan Nama Aset"
                />
              </div>
              <div>
                <label
                  for="aset"
                  class="text-sm text-gray-700 block mb-1 font-medium"
                >
                  Data Anggota Keluarga
                  <button
                    class="inline-flex items-center bg-blue-500 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded ml-2 text-sm"
                    type="button"
                    onClick={() => setShowModal(true)}
                  >
                    <PlusIcon className="h-4 w-4 mr-2" />
                    Tambah
                  </button>
                </label>
                <div class="bg-white p-5 py-20 rounded-lg shadow-sm text-center">
                  <p class="text-gray-400 text-sm">Belum Ada Data!</p>
                  <p class="text-gray-400 text-sm">
                    Silahkan Tambahkan Anggota Keluarga
                  </p>
                </div>
              </div>
            </div>

            <div class="space-x-4 mt-8">
              <button
                type="submit"
                class="inline-flex items-center px-5 py-3 text-sm font-medium text-white bg-cyan-700 hover:bg-cyan-800 rounded-md font-semibold transition ease-in-out duration-300 sm:px-8 sm:py-3"
              >
                <CheckIcon className="h-4 w-4 inline mr-2" />
                Simpan
              </button>

            <Link to="/dasbor">
              <button 
              type="button"
              class="inline-flex items-center px-5 py-3 text-sm font-medium text-white bg-red-500 hover:bg-red-600 rounded-md font-semibold transition ease-in-out duration-300 sm:px-8 sm:py-3">
                <XMarkIcon className="h-4 w-4 inline mr-2" />
                Batal
              </button>
              </Link>
            </div>
          </form>
        </div>
      </div>
      <Footer />

      {showModal ? (
        <>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div
              className="fixed inset-0 w-full h-full bg-black opacity-70"
              onClick={() => setShowModal(false)}
            ></div>
            <div className="flex items-center min-h-screen px-4 py-8">
              <div className="relative w-full max-w-6xl p-4 mx-auto bg-slate-100 rounded-md shadow-lg">
                <div className="m-6">
                  <div className="mt-2 text-center sm:ml-4 sm:text-left">
                    <h4 className="text-lg font-bold text-gray-800">
                      Tambah Anggota Keluarga
                    </h4>
                    <div>
                      <div class="mt-6">
                        <label
                          for="nomor-kk"
                          class="text-sm text-gray-700 block mb-1 font-medium"
                        >
                          Nomor Induk Kependudukan (NIK)
                        </label>
                        <input
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          maxLength={16}
                          minLength={16}
                          type="text"
                          name="nomor-kk"
                          id="nomor-kk"
                          class="w-full p-4 pr-12 text-sm rounded-lg shadow-sm focus:outline-cyan-700 focus:outline-offset-0 focus:text-cyan-700 focus:bg-gray-100 text-cyan-700 font-medium"
                          autocomplete="off"
                          placeholder="Masukkan Nomor KK"
                        />
                      </div>
                      <div class="mt-4">
                        <label
                          for="nomor-kk"
                          class="text-sm text-gray-700 block mb-1 font-medium"
                        >
                          Nama Lengkap
                        </label>
                        <input
                          type="text"
                          name="nomor-kk"
                          id="nomor-kk"
                          class="w-full p-4 pr-12 text-sm rounded-lg shadow-sm focus:outline-cyan-700 focus:outline-offset-0 focus:text-cyan-700 focus:bg-gray-100 text-cyan-700 font-medium"
                          autocomplete="off"
                          placeholder="Masukkan Nomor KK"
                        />
                      </div>
                      <div class="mt-4">
                        <label
                          for="nomor-kk"
                          class="text-sm text-gray-700 block mb-1 font-medium"
                        >
                          Jenis Kelamin
                        </label>
                        <input
                          maxLength={16}
                          minLength={16}
                          type="text"
                          name="nomor-kk"
                          id="nomor-kk"
                          class="w-full p-4 pr-12 text-sm rounded-lg shadow-sm focus:outline-cyan-700 focus:outline-offset-0 focus:text-cyan-700 focus:bg-gray-100 text-cyan-700 font-medium"
                          autocomplete="off"
                          placeholder="Masukkan Nomor KK"
                        />
                      </div>
                      <div class="mt-4">
                        <label
                          for="desa"
                          class="text-sm text-gray-700 block mb-1 font-medium"
                        >
                          Jenjang Pendidikan
                        </label>
                        <select
                          id="desa"
                          disabled={false}
                          value={pendidikan}
                          onChange={(e) => setPendidikan(e.currentTarget.value)}
                          defaultValue=""
                          class="bg-white rounded-lg shadow-sm text-cyan-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full font-bold py-4 focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 font-bold focus:outline-cyan-700 focus:outline-offset-0 focus:text-cyan-700 focus:bg-gray-100"
                        >
                          <option value="" disabled>
                            Pilih Jenjang Pendidikan
                          </option>
                          {optionPendidikan.map((item) => (
                            <option key={item._id} value={item.id}>
                              {item.nama}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div class="mt-4">
                        <label
                          for="nomor-kk"
                          class="text-sm text-gray-700 block mb-1 font-medium"
                        >
                          Agama
                        </label>
                        <select
                          id="countries"
                          class="bg-white rounded-lg shadow-sm text-cyan-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full font-bold py-4 focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 font-bold focus:outline-cyan-700 focus:outline-offset-0 focus:text-cyan-700 focus:bg-gray-100"
                        >
                          <option selected>Pilih Agama</option>
                          <option value="US">Islam</option>
                          <option value="CA">Kristen</option>
                          <option value="FR">Protestan</option>
                          <option value="DE">Hindu</option>
                          <option value="DE">Budha</option>
                        </select>
                      </div>
                      <div class="mt-4">
                        <label
                          for="nomor-kk"
                          class="text-sm text-gray-700 block mb-1 font-medium"
                        >
                          Status Rumah
                        </label>
                        <input
                          maxLength={16}
                          minLength={16}
                          type="text"
                          name="nomor-kk"
                          id="nomor-kk"
                          class="w-full p-4 pr-12 text-sm rounded-lg shadow-sm focus:outline-cyan-700 focus:outline-offset-0 focus:text-cyan-700 focus:bg-gray-100 text-cyan-700 font-medium"
                          autocomplete="off"
                          placeholder="Masukkan Nomor KK"
                        />
                      </div>
                      <div class="mt-4">
                        <label
                          for="nomor-kk"
                          class="text-sm text-gray-700 block mb-1 font-medium"
                        >
                          Jarak Puskesmas dari Rumah
                        </label>
                        <input
                          maxLength={16}
                          minLength={16}
                          type="text"
                          name="nomor-kk"
                          id="nomor-kk"
                          class="w-full p-4 pr-12 text-sm rounded-lg shadow-sm focus:outline-cyan-700 focus:outline-offset-0 focus:text-cyan-700 focus:bg-gray-100 text-cyan-700 font-medium"
                          autocomplete="off"
                          placeholder="Masukkan Nomor KK"
                        />
                      </div>
                      <div class="mt-4">
                        <label
                          for="nomor-kk"
                          class="text-sm text-gray-700 block mb-1 font-medium"
                        >
                          Penerangan Rumah
                        </label>
                        <input
                          maxLength={16}
                          minLength={16}
                          type="text"
                          name="nomor-kk"
                          id="nomor-kk"
                          class="w-full p-4 pr-12 text-sm rounded-lg shadow-sm focus:outline-cyan-700 focus:outline-offset-0 focus:text-cyan-700 focus:bg-gray-100 text-cyan-700 font-medium"
                          autocomplete="off"
                          placeholder="Masukkan Nomor KK"
                        />
                      </div>
                      <div class="mt-4">
                        <label
                          for="aset"
                          class="text-sm text-gray-700 block mb-1 font-medium"
                        >
                          Aset
                        </label>
                        <textarea
                          type="text"
                          value={aset}
                          onChange={(e) => setAset(e.target.value)}
                          name="aset"
                          id="aset"
                          class="w-full p-4 pr-12 text-sm rounded-lg shadow-sm focus:outline-cyan-700 focus:outline-offset-0 focus:text-cyan-700 focus:bg-gray-100 text-cyan-700 font-medium"
                          placeholder="Masukkan Nama Aset"
                        />
                      </div>
                    </div>
                    <div className="space-x-4 mt-8">
                      <button
                        className="inline-block px-5 py-3 text-sm font-medium text-white bg-cyan-700 hover:bg-cyan-800 rounded-md font-semibold transition ease-in-out duration-300 sm:px-8 sm:py-3"
                        onClick={() => setShowModal(false)}
                      >
                        Batal
                      </button>
                      <button
                        className="inline-block px-5 py-3 text-sm font-medium text-white bg-red-500 hover:bg-red-600 rounded-md font-semibold transition ease-in-out duration-300 sm:px-8 sm:py-3 "
                        onClick={() => setShowModal(false)}
                      >
                        Tambah
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}
