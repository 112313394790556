import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

export default function Example() {
  const [nama, setNama] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [kecamatan, setKecamatan] = useState("");
  const [kecamatanId, setKecamatanId] = useState("");
  const [desa, setDesa] = useState("");
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const [passwordShown, setPasswordShown] = useState(false);

  // const [loading, setLoading] = useState(false);

  const [optionKecamatan, setOptionKecamatan] = useState([]);
  const [optionDesa, setOptionDesa] = useState([]);

  const Register = async (e) => {
    e.preventDefault();
    // this.setLoading({
    //   loading: true
    // })
    // setTimeout(() => {
    //   this.setLoading({ loading: false });
    // }, 3000);
    try {
      await axios.post(
        "https://bigdata.pelalawankab.go.id/api/auth/user-register",
        {
          name: nama,
          username: username,
          email: email,
          password: password,
          desa_id: desa,
        }
      );
      navigate("/login");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.msg);
      }
    }
  };

  const fetchKecamatan = () => {
    axios
      .get("https://bigdata.pelalawankab.go.id/api/general/kecamatan")
      .then((response) => {
        const { data } = response;
        if (response.status === 200) {
          //check the api call is success by stats code 200,201 ...etc
          setOptionKecamatan(data.data);
        } else {
          //error handle section
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchKecamatan();
  }, []);

  const fetchDesa = () => {
    axios
      .get(
        `https://bigdata.pelalawankab.go.id/api/general/filter-kecamatan/${kecamatan}`
      )
      .then((response) => {
        const { data } = response;
        if (response.status === 200) {
          //check the api call is success by stats code 200,201 ...etc
          setOptionDesa(data.data.list_desa);
        } else {
          //error handle section
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (kecamatan !== "") {
      fetchDesa();
    }
  }, [kecamatan]);

  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  const validate = () => {
    if (
      nama === "" ||
      email === "" ||
      username === "" ||
      password === "" ||
      kecamatan === "" ||
      desa === ""
    ) {
      setMsg("Please fill all the fields");
      return false;
    } else {
      return true;
    }
  };

  // export const getDesa = (id) => {
  //   let result = url
  //     .get(`/general/filter-kecamatan/${id}`)
  //     .then((response) => {
  //       return response.data;
  //     })
  //     .catch((error) => {
  //       return error;
  //     });

  //   return result;
  // };

  return (
    <div className="flex flex-col lg:flex-row h-screen bg-slate-100">
      <div className="w-full lg:w-1/2 h-screen flex items-center justify-center">
        <div className="max-w-md mx-7 md:mx-0">
          <h1 className="text-2xl lg:text-4xl tracking-tight leading-none font-black text-cyan-700 animate-text font-jakarta italic">
            Daftar
          </h1>
          <p className="text-gray-500 mt-2 font-jakarta lg:text-md code">
            Untuk mendaftar pada aplikasi ini, silahkan lengkapi form dibawah
            ini
          </p>

          <form onSubmit={Register} className="mx-auto mt-6 mb-0 space-y-4">
            <div>
              <label htmlFor="nama" className="sr-only">
                Nama Lengkap
              </label>

              <div className="relative">
                <input
                  required
                  type="text"
                  className="w-full p-4 pr-12 text-sm rounded-lg shadow-sm focus:outline-cyan-700 focus:outline-offset-0 focus:text-cyan-700 focus:bg-gray-100 text-cyan-700 font-medium"
                  autoComplete="off"
                  value={nama}
                  onChange={(e) => setNama(e.target.value)}
                  placeholder="Masukkan nama lengkap"
                />

                <span className="absolute inset-y-0 inline-flex items-center right-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </span>
              </div>
            </div>

            <div>
              <label htmlFor="username" className="sr-only">
                Username
              </label>

              <div className="relative">
                <input
                  type="text"
                  className="w-full p-4 pr-12 text-sm rounded-lg shadow-sm focus:outline-cyan-700 focus:outline-offset-0 focus:text-cyan-700 focus:bg-gray-100 text-cyan-700 font-medium"
                  autoComplete="off"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="Masukkan username"
                />

                <span className="absolute inset-y-0 inline-flex items-center right-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                    />
                  </svg>
                </span>
              </div>
            </div>

            <div>
              <label htlmFor="email" className="sr-only">
                Email
              </label>

              <div className="relative">
                <input
                  type="email"
                  className="w-full p-4 pr-12 text-sm rounded-lg shadow-sm focus:outline-cyan-700 focus:outline-offset-0 focus:text-cyan-700 focus:bg-gray-100 text-cyan-700 font-medium"
                  autoComplete="off"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Masukkan email"
                />

                <span className="absolute inset-y-0 inline-flex items-center right-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                    />
                  </svg>
                </span>
              </div>
            </div>

            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 right-0 flex items-center px-2 mr-10">
                  <input
                    className="hidden js-password-toggle"
                    id="toggle"
                    type="checkbox"
                  />
                  <label
                    onClick={togglePassword}
                    className="bg-gray-300 hover:bg-gray-400 rounded px-2 py-1 text-sm text-gray-600 font-mono cursor-pointer js-password-label"
                    htmlFor="toggle"
                  >
                    {passwordShown ? "Tutup" : "Lihat"}
                  </label>
                </div>
                <input
                  type={passwordShown ? "text" : "password"}
                  className="w-full p-4 pr-12 text-sm rounded-lg shadow-sm focus:outline-cyan-700 focus:outline-offset-0 focus:text-cyan-700 focus:bg-gray-100 text-cyan-700 font-medium"
                  value={password}
                  autoComplete="off"
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Masukkan password"
                />

                <span className="absolute inset-y-0 inline-flex items-center right-4">
                  {passwordShown ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                      />
                    </svg>
                  )}
                </span>
              </div>
            </div>

            <div className="pt-4">
              <label className="block text-gray-500 text-sm font-medium">
                Pilih wilayah tugas
              </label>
            </div>
            <div>
              <select
                required
                id="kecamatan"
                className="bg-white rounded-lg shadow-sm text-cyan-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full font-bold py-4 focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 font-bold focus:outline-cyan-700 focus:outline-offset-0 focus:text-cyan-700 focus:bg-gray-100"
                disabled={false}
                value={kecamatan}
                onChange={(e) => setKecamatan(e.currentTarget.value)}
                defaultValue=""
              >
                <option value="" disabled>
                  Pilih Kecamatan
                </option>
                {optionKecamatan.map((item) => (
                  <option key={item._id} value={item.id}>
                    {item.nama}
                  </option>
                ))}
              </select>
            </div>

            <select
              id="desa"
              disabled={false}
              value={desa}
              onChange={(e) => setDesa(e.currentTarget.value)}
              className="bg-white rounded-lg shadow-sm text-sm rounded-lg py-4 block w-full p-2.5 font-bold focus:outline-cyan-700 focus:outline-offset-0 focus:text-cyan-700 focus:bg-gray-100 text-cyan-700"
              defaultValue=""
            >
              <option value="" disabled>Pilih Desa/ Kelurahan</option>
              {optionDesa.map((item) => (
                <option key={item._id} value={item.id}>
                  {item.nama}
                </option>
              ))}
            </select>

            <div className="flex items-center justify-between">
              <p className="text-sm text-gray-500">
                Sudah punya akun?{" "}
                <Link
                  className="underline" 
                  to="/login">
                  Masuk
                </Link>
              </p>

              <button
                type="submit"
                className="inline-block px-5 py-3 text-sm font-medium text-white bg-cyan-700 hover:bg-cyan-800 rounded-md font-semibold transition ease-in-out duration-300 sm:px-8 sm:py-3 "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 inline mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
                  />
                </svg>
                Daftar
              </button>
            </div>
          </form>

          {/* <div className="md:flex md:items-center"> */}
          {/* <img className=" h-16 mt-20 mx-auto" src={require("../assets/google-play-badge.png")} /> */}
          {/* </div>  */}
        </div>
      </div>
      <div className="w-full lg:w-1/2 md:h-1/2 h-screen hidden lg:block">
        <div className="object-cover w-full h-screen from-transparent to-blue-200 bg-pelalawan bg-cover bg-center"></div>
        <div className="absolute top-0 right-0 bottom-0 left-1/2 lg:w-1/2 bg-gradient-to-l from-transparent to-cyan-900"></div>
      </div>
      <div className="py-1 bg-cyan-700 md:flex md:items-center md:justify-between"></div>
    </div>
  );
}
