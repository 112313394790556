import Header2 from "../components/Header2";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import axios from "axios";
import { useState, useEffect } from "react";
import moment from "moment";
import 'moment/locale/id';
import { Link } from "react-router-dom";
moment.locale('id');

export default function Dasbor() {
  const [profil, setProfil] = useState([]);
  const [keluarga, setKeluarga] = useState([]);
  const [dasbor, setDasbor] = useState([]);
  const token = localStorage.getItem("token");

  const fetchProfil = () => {
    let config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    axios
      .get("https://bigdata.pelalawankab.go.id/api/auth/user-profile", config)
      .then((response) => {
        // console.log(response.data.data);
        setProfil(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchProfil();
  }, []);

  const fetchKeluarga = () => {
    let config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    axios
      .get(
        "https://bigdata.pelalawankab.go.id/api/dashboard/list-keluarga/10",
        config
      )
      .then((response) => {
        const { data } = response;
        if (response.status === 200) {
          setKeluarga(data.data);
          setDasbor(data);
        } else {
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchKeluarga();
  }, []);


  const totalKeluraga = dasbor.total;
  const totalPenduduk = dasbor.sipils_total;

  return (
    <div class="bg-slate-100">
      <Navbar />
      <Header2 />

      <div class="container items-center max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 mt-5 ">
        <h1 class="text-xl font-semibold my-4">Dasbor Pengguna</h1>
        <div class="flex flex-wrap pb-3 mx-4 md:mx-24 lg:mx-0">
          <div class="w-full p-2 lg:w-1/4 md:w-1/2">
            <Link to="/tambah">
              <div class="flex flex-col px-6 py-10 overflow-hidden bg-gradient-to-br from-cyan-700 via-cyan-600 to-cyan-500 rounded-lg shadow-lg duration-300 transition ease-in-out hover:bg-gradient-to-br hover:from-gray-700 hover:via-gray-600 hover:to-gray-500 hover:shadow-2xl group">
                <div class="animation-bounce flex items-center space-x-3">
                  <PlusCircleIcon className="h-12 w-12 text-white group-hover:text-white" />
                  <h3 class="text-white group-hover:text-white font-bold text-lg leading-[1.3rem]">
                    Tambah Data Keluarga
                  </h3>
                </div>
                <p class="text-white group-hover:text-white text-sm mt-6">
                  Tekan tombol ini untuk melakukan penambahan data keluarga dan
                  anggota keluarga
                </p>
              </div>
            </Link>
          </div>

          <div class="w-full p-2 lg:w-1/4 md:w-1/2 ">
            <div class="flex flex-col px-6 py-10 overflow-hidden bg-white hover:bg-gradient-to-br hover:from-cyan-700 hover:via-cyan-600 hover:to-cyan-500 rounded-lg shadow-lg duration-300 transition ease-in-out hover:shadow-2xl group">
              <div class="flex flex-row justify-between items-center">
                <div class="px-4 py-4 bg-cyan-300  rounded-xl bg-opacity-50">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 group-hover:text-gray-50 text-cyan-700"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                    />
                  </svg>
                </div>
              </div>
              <h1 class="text-2xl sm:text-2xl xl:text-2xl font-black text-gray-700 mt-6 group-hover:text-gray-50">
                {totalKeluraga}
              </h1>
              <div class="flex flex-row justify-between text-gray-600 group-hover:text-gray-200">
                <p class="text-sm">Data Keluarga</p>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-cyan-700 group-hover:text-gray-200"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>

          <div class="w-full p-2 lg:w-1/4 md:w-1/2 ">
            <div class="flex flex-col px-6 py-10 overflow-hidden bg-white hover:bg-gradient-to-br hover:from-cyan-700 hover:via-cyan-600 hover:to-cyan-500 rounded-lg shadow-lg duration-300 transition ease-in-out hover:shadow-2xl group">
              <div class="flex flex-row justify-between items-center">
                <div class="px-4 py-4 bg-cyan-300  rounded-xl bg-opacity-50">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 group-hover:text-gray-50 text-cyan-700"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                    />
                  </svg>
                </div>
              </div>
              <h1 class="text-2xl sm:text-2xl xl:text-2xl font-black text-gray-700 mt-6 group-hover:text-gray-50">
                {totalPenduduk}
              </h1>
              <div class="flex flex-row justify-between text-gray-600 group-hover:text-gray-200">
                <p class="text-sm">Data Penduduk</p>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-cyan-700 group-hover:text-gray-200"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>

          <div class="w-full p-2 lg:w-1/4 md:w-1/2">
            <div class="flex flex-col px-6 py-10 overflow-hidden bg-white hover:bg-gradient-to-br hover:from-cyan-700 hover:via-cyan-600 hover:to-cyan-500 rounded-xl shadow-lg duration-300 hover:shadow-2xl group">
              <div class="flex flex-row justify-between items-center">
                <div class="px-4 py-4 bg-gray-300  rounded-xl bg-opacity-30">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 group-hover:text-gray-50"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M5 4a2 2 0 012-2h6a2 2 0 012 2v14l-5-2.5L5 18V4z" />
                  </svg>
                </div>
                <div class="inline-flex text-sm text-gray-600 group-hover:text-gray-200 sm:text-base">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 mr-2 text-green-500 group-hover:text-gray-200"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  12%
                </div>
              </div>
              <h1 class="text-3xl sm:text-2xl xl:text-2xl font-bold text-gray-700 mt-12 group-hover:text-gray-50">
                42.34%
              </h1>
              <div class="flex flex-row justify-between group-hover:text-gray-200 font-jakarta">
                <p>Page Per Visits</p>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-cyan-600 group-hover:text-gray-200"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>

          <div class="w-full">
            <div class="max-w-7xl mx-auto">
              <div class="flex flex-col">
                <div class="mb-2">
                  <h1 class="text-xl font-bolder leading-tight text-gray-900 font-jakarta my-6 font-semibold">
                    Data Keluarga Terbaru
                  </h1>
                </div>

                <div class="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                  <div class="align-middle inline-block w-full shadow overflow-x-auto sm:rounded-lg border-b border-gray-200">
                    <table class="min-w-full leading-normal">
                      <thead>
                        <tr>
                          <th class="px-5 py-3 border-b-2 border-gray-200 bg-cyan- text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                            Nomor/ Kepala Keluarga
                          </th>
                          <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                            Anggota Keluarga
                          </th>
                          <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                            Tanggal Input
                          </th>
                          <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider content-center text-center">
                            Aksi
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                      {keluarga.map((item) => (
                          <tr>
                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <div class="flex">
                                <div class="ml-3">
                                  <p class="text-gray-800 whitespace-no-wrap font-medium">
                                    {item.no_kk}
                                  </p>
                                  <p class="text-gray-400 whitespace-no-wrap">
                                    {item.nama_kepala_keluarga}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <p class="text-gray-900 whitespace-no-wrap">
                              {item.jml_anggota_keluarga} Orang
                            </p>
                          </td>
                          <td class="px-5 py-5 border-b border-gray-200 bg-white">
                            <p class="text-gray-800 whitespace-no-wrap text-sm">
                              {moment(item.dibuat_pada).format('DD MMMM YYYY')}
                            </p>
                            <p class="text-gray-400 whitespace-no-wrap text-xs">
                              {item.diunggah}
                            </p>
                          </td>
                          <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                            <Link to="/detail/">
                              <span class="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight mr-1">
                                <span
                                  aria-hidden
                                  class="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                                ></span>
                                <span class="relative">Lihat</span>
                              </span>
                            </Link>
                            <a href="">
                              <span class="relative inline-block px-3 py-1 font-semibold text-yellow-900 leading-tight mr-1">
                                <span
                                  aria-hidden
                                  class="absolute inset-0 bg-yellow-200 opacity-50 rounded-full"
                                ></span>
                                <span class="relative">Edit</span>
                              </span>
                            </a>
                            <a href="">
                              <span class="relative inline-block px-3 py-1 font-semibold text-red-900 leading-tight">
                                <span
                                  aria-hidden
                                  class="absolute inset-0 bg-red-200 opacity-50 rounded-full"
                                ></span>
                                <span class="relative">Hapus</span>
                              </span>
                            </a>
                          </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div>
                <Link to="/data">
                  <button class="bg-gray-800 w-full  my-8 px-auto py-4 rounded-md text-white">
                    Lihat semua data
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* <div class="flex items-center bg-cyan-700 text-white text-sm font-bold px-4 py-3 rounded-md my-4 b" role="alert">
  <svg class="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z"/></svg>
  <p>Something happened that you should know about.</p>
</div> */}
      </div>
      {/* <div class="">
<svg class="absolute inset-0 h-full w-full" xmlns="http://www.w3.org/2000/svg"><defs><pattern id="pricing-pattern" width="32" height="32" patternUnits="userSpaceOnUse" x="50%" y="100%" patternTransform="translate(0 -1)"><path d="M0 32V.5H32" fill="none" stroke="currentColor"></path></pattern></defs><rect width="100%" height="100%" fill="url(#pricing-pattern)"></rect></svg>
</div> */}
      <Footer />
    </div>
  );
}
