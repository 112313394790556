import { PlusIcon, MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import "moment/locale/id";
import { Link } from "react-router-dom";
moment.locale("id");

export default function Dasbor() {
  const [keluarga, setKeluarga] = useState([]);
  const [dasbor, setDasbor] = useState([]);
  const token = localStorage.getItem("token");

  const fetchKeluarga = () => {
    let config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    axios
      .get(
        "https://bigdata.pelalawankab.go.id/api/dashboard/list-keluarga/10",
        config
      )
      .then((response) => {
        const { data } = response;
        if (response.status === 200) {
          setKeluarga(data.data);
          setDasbor(data);
        } else {
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchKeluarga();
  }, []);

  const totalKeluraga = dasbor.total;

  return (
    <div class="w-full bg-slate-100">
      <Navbar />
      <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div class="flex flex-col">
          <div class="mb-2">
            <h1 class="text-2xl font-bolder leading-tight text-gray-900 font-jakarta mt-6 font-medium">
              Data Keluarga ({totalKeluraga})
            </h1>
          </div>
          <div class="mb-3 py-4 flex flex-wrap flex-grow justify-between">
            <div class="flex items-center py-2">
              <input
                class="pl-10 bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-cyan-800"
                id="inline-searcg"
                type="text"
                placeholder="Cari"
              />
              <div class="absolute inline-flex items-center p-4">
                <MagnifyingGlassIcon className="h-4 w-4 inline mr-2" />
              </div>
            </div>
            <div class="flex items-center py-2">
              <Link
                to="/tambah"
                class="inline-block px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-cyan-700 hover:bg-cyan-800 focus:outline-none focus:shadow-outline"
              >
                <PlusIcon className="h-4 w-4 inline mr-2" />
                Tambah Data Keluarga
              </Link>
            </div>
          </div>
          <div class="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
            <div class="align-middle inline-block w-full shadow overflow-x-auto sm:rounded-lg border-b border-gray-200">
              <table class="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                      Nomor/ Kepala Keluarga
                    </th>
                    <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                      Anggota Keluarga
                    </th>
                    <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                      Tanggal Input
                    </th>
                    <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider content-center text-center">
                      Aksi
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {keluarga.map((item) => (
                    <tr>
                      <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <div class="flex">
                          <div class="ml-3">
                            <p class="text-gray-800 whitespace-no-wrap font-bold">
                              {item.no_kk}
                            </p>
                            <p class="text-gray-400 whitespace-no-wrap">
                              {item.nama_kepala_keluarga}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <p class="text-gray-900 whitespace-no-wrap">
                          {item.jml_anggota_keluarga} Orang
                        </p>
                      </td>
                      <td class="px-5 py-5 border-b border-gray-200 bg-white">
                        <p class="text-gray-800 whitespace-no-wrap text-sm">
                          {moment(item.dibuat_pada).format("DD MMMM YYYY")}
                        </p>
                        <p class="text-gray-400 whitespace-no-wrap text-xs">
                          {item.diunggah}
                        </p>
                      </td>
                      <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <a href="/detail/">
                          <span class="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight mr-1">
                            <span
                              aria-hidden
                              class="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                            ></span>
                            <span class="relative">Lihat</span>
                          </span>
                        </a>
                        <a href="">
                          <span class="relative inline-block px-3 py-1 font-semibold text-yellow-900 leading-tight mr-1">
                            <span
                              aria-hidden
                              class="absolute inset-0 bg-yellow-200 opacity-50 rounded-full"
                            ></span>
                            <span class="relative">Edit</span>
                          </span>
                        </a>
                        <a href="">
                          <span class="relative inline-block px-3 py-1 font-semibold text-red-900 leading-tight">
                            <span
                              aria-hidden
                              class="absolute inset-0 bg-red-200 opacity-50 rounded-full"
                            ></span>
                            <span class="relative">Hapus</span>
                          </span>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
